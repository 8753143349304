











import { defineComponent, reactive } from '@vue/composition-api'

export default defineComponent({
  components: { ORequestList: () => import('@/components/organisms/o-request-list.vue') },

  setup() {
    const state = reactive({
      isLoading: false
    })

    return { state }
  }
})
